body {
  background-color: #edf1f6;
}

.ant-carousel .slick-dots li button {
  background: white;
  border: 1px solid black;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  height: 12px;
  width: 12px;
  border-radius: 50px;
  background: black;
}

.ant-modal-header {
  border-bottom: none;
  border-radius: 15px;
}

.ant-modal-content {
  border-radius: 15px;
}

.ant-picker,
.ant-picker-focused {
  border: none !important;
  box-shadow: none !important;
}

.ant-input:focus,
.ant-input,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
  transition: none !important;
  background-color: transparent !important;
}
.ant-input-affix-wrapper.custom-inp:hover {
  border-color: #093479; 
}
.ant-form-item.ant-form-item-has-error .custom-inp {
  border-color: #ff4d4f;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  transition: none !important;
}

/* .ant-popover {
  max-width: 200px;
}

.ant-popover-content {
  border-radius: 10px;
}

.ant-popover-inner {
  background-color: #231f20;
  border-radius: 10px;
}

.ant-popover-inner-content {
  color: white;
}

.ant-popover-arrow {
  background-color: #231f20 !important;
  border-color: #231f20 !important;
} */

.ant-spin-dot-item {
  background-color:#093479; 
}

._loading_overlay_overlay {
  position: fixed;
}
/* success */
/* .ant-form-item-has-success .custom-inp {
  content: ' ';
  display: inherit;
  background: url('./images/success.svg') 96% 50% / 15px 15px no-repeat;
  width: 100%;
  height: 100%;
} */

.ant-form-item-has-success .ant-input:focus,
.ant-form-item-has-success .ant-input:not(:focus),
.ant-form-item-has-success .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  border-color: #58bb55;
  border-right-width: 1px !important;
  outline: 0;
}
/* error */
/* .ant-form-item-has-error .custom-inp {
  content: ' ';
  display: inherit;
  background: url('./images/error.svg') 96% 50% / 14px 14px no-repeat;
  width: 100%;
  height: 100%;
} */
.ant-input-clear-icon-hidden {
  display: none;
  margin-bottom: 6px;
}
.ant-input-suffix {
  right: 10px !important;
}
input[type='password'] {
  color: #093479; 
}
span > svg {
  margin-bottom: 5px;
}
.ant-form-item:not(.ant-form-item-has-error):not(.ant-form-item-has-success) .reqDot {
  height: 6px;
  width: 6px;
  background: #093479;
  border-radius: 50px;
  position: absolute;
  top: 42%;
  right: 16px;
}

/* for making select pink in select option  */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: white;
  background-color: #093479; 
}

/* For making scrollbar pink in select option dropdown */
.rc-virtual-list-scrollbar-thumb {
  background: #093479  !important;
}

.search-prod {
  position: relative;
}
.search-prod input {
  text-indent: 30px;
}
.search-prod .search-icon {
  position: absolute;
  top: 5px;
  left: 12px;
  font-size: 15px;
}

.bg-neu-bg {
  min-height: 100vh;
}

.ant-table-thead > tr > th {
  font-weight: 700;
  font-size: 11px;
}

.ant-table-tbody > tr > td {
  font-size: 12px;
}

.ant-form-item-explain-error > div {
  font-weight: 600;
  font-size: 12px;
  color: #e11f20;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

span.ant-input-suffix {
  background-color: white;
  right: 35px;
  padding-left: 5px;
  position: absolute;
}

.ant-input-suffix {
  display: inline !important;
}

#mySidebar:hover {
  overflow-y: auto;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #093479  !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #093479; 
}

.ant-radio:hover .ant-radio-inner {
  border-color: #093479; 
}

.importTable tr {
  text-align: left;
}

.importTable td {
  padding-top: 5px;
  padding-bottom: 6px;
}

.import-prod__upload-file {
  margin-bottom: 4px;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: #212529;
}

.import-prod__upload-porgress {
  position: relative;
  width: 100%;
  height: 1px;
  border: solid 2px #f9f9f9;
}

.import-prod__upload-complete {
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  background-color: #5cba3a;
}

.import-prod__upload-complete.animate {
  animation: progress 1.5s linear forwards;
  animation-delay: 0.3s;
}

.import-prod__upload-complete.animate.error {
  background-color: #e55050;
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.upload-block__file-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.upload-block__file-info .glyphicon-ok {
  color: #5cba3a;
}

.upload-block__file-info .glyphicon-remove {
  color: #e55050;
}

.ant-collapse-borderless > .ant-collapse-item,
.ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}

.ant-collapse,
.ant-collapse-borderless {
  background-color: #fff !important;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  margin-left: 25px;
}

.ant-switch-checked {
  background-color: #093479 !important;
}

.assocProdsTable th,
.assocProdsTable td {
  text-align: left;
}

._loading_overlay_content {
  position: fixed;
  top: 50%;
  right: 50%;
}

.ant-table-thead .ant-table-cell {
  background-color: #eff2f5;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #093479; 
}
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  color: #fff;
  background: #093479; 
  border-radius: 22px;
}
.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover{
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  border-radius: 41px;
}
.ant-btn-primary {
  color: #fff;
  border-color: #093479; 
  background: #093479; 
  border-radius: 25px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #093479; 
  background: #093479; 
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #093479; 
  border-radius: 25px;
}
a.ant-picker-now-btn:hover {
  color: #093479; 
}
.tracking-tight {
  letter-spacing: 0px !important;
}
._loading_overlay_wrapper--active {
  overflow: hidden !important;
  height: 100vh;
}
.ant-active-sidebar {
  background-color: #3A6BBF;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-modal-wrap{
  z-index: 100;
}

.ant-modal-mask{
  z-index: 100;
}

.overflow-scroll::-webkit-scrollbar {
  display: none;
}